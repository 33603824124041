<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import feather from 'feather-icons'
export default {
  name: 'App',
  components: {
  },
  mounted() {
    feather.replace()
    this.scrollToTop()

  },
  
  methods: {
    scrollToTop() {
        window.scrollTo(0,0);
    }
  },
}
</script>

<style></style>
