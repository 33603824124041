import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/scss/tailwind.scss'
import "@/assets/libs/@mdi/font/css/materialdesignicons.min.css"
import '@/assets/libs/tobii/css/tobii.min.css'
import VueEasyLightbox from 'vue-easy-lightbox'
import router from './router';


const app = createApp(App)

app.use(VueEasyLightbox)
app.use(router);
app.mount('#app')


    